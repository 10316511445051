.PerfilContainer {
    display: flex;
    background-color: #eeeeee;
    flex-direction: column;
    padding: 8rem 3rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: "Montserrat", sans-serif;
}

.TituloPerfil {
    text-align: center;
    padding: 0 2rem;
    padding-bottom: 2rem;
    font-weight: 600;
    color: black;
}

/* card de perfil - Donde se ve la informacion - container padre */
.ProfileInformation {
    display: flex;
    justify-content: center;
    background-color: #dd4c4c;
    border-radius: 30px;
    width: 50%;
    /* Con esto regulo el ancho de la card padre, son dos container este y el infogrid */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.169);
}

.InfoGridContainer {
    width: 100%;
    /* con esto regulo el ancho del texto dentro de la card, son como dos card dentro de si mismas */
}

.TituloCardPrincipal {
    word-break: keep-all;

}

.containerFoto {
    display: flex;
    width: 100%;
    justify-content: center;
}

.TextoVentanaPrincipal {
    word-break: break-all;
}

.ImagePerfil {
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    height: auto;
    border-radius: 20px 20px 0 0;

}

.infoPerfil {
    font-family: inherit;
    display: flex;
    flex-direction: column;
    padding: 4rem;
}

.ContainerDataSave {
    width: 100%;
    display: flex;
    justify-content: left;
    flex-direction: column;
    align-items: left;
    padding: 1rem 0;
    box-sizing: border-box;
}

.form-label {
    font-weight: 600;
    font-size: 1rem;
}

.TituloDataSave {
    font-weight: 600;


}


.DatoSave {
    font-weight: 600;
}

.TituloDropdown {
    font-weight: 600;
    font-size: 1.2rem;
}

.BotonesPerfil {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#btnEditarPerfil {
    width: 100%;
    padding: .4rem 2rem;
    margin-top: 20px;
    /* Agregamos un margen superior para separarlo del resto de los elementos */
}

.DataPefil {
    margin: 10px 0;
    width: 100%;
    word-break: keep-all;

}

.descripcion {
    width: 100%;
    white-space: pre-wrap;
    word-break: keep-all;

}


/* estilo datos modificables */

.containerTextoPerfil {
    background-color: #e7e6e6;
    border-radius: 0 0 30px 30px;
    padding-top: 2rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
}

.containerBtnGuardarCancelar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#BotonGuardarPerfil {
    width: 50%;
    padding: .7rem 2rem;
    border-radius: 10px;
    border: none;
    outline: none;
    transition: .4s ease-in-out;
    background-color: #0c6dfd;
    color: white;
}

#BotonGuardarPerfil:hover {
    background-color: #387CDF;
}

/* gestion de cuenta */

.AccountManagement {
    padding: 3rem 2.5rem;
    width: 100%;
}

.ManejoDeCuentaTitulo {
    margin-bottom: 2rem;
    font-weight: 700;
    text-align: center;
}

#FormManejoCuenta {
    /* padding: 1rem; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#FormManejoCuenta .form-label {
    text-align: left;
}

.containerBtnCambiarEmail {
    display: flex;
    justify-content: center;
    padding-bottom: 1.5rem;
    padding-top: 1rem;
}

/* estilo de boton cambiar email */
.btnCambiarEmail {
    width: 50%;
}

/* container para de btn cambiar contrasena */

.containerGuardarContrasena {
    display: flex;
    justify-content: center;
}

/* estilos de boton cambiar contrasena */
.BtnGuardarContrasena {
    width: 50%;
}

.ContainerBotonGestionCuenta {
    display: flex;
    flex-direction: column;
}

.containerEditarInformacion {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    /* border: ; */
}

#BtnEditarInfo {
    background-color: #0c6dfd;
    margin: 0 auto;
    width: 50%;
    padding: .6rem 2rem;
    border-radius: 10px;
    border: none;
    outline: none;
    transition: .4s ease-in-out;
    transition: .2s all ease;
    color: white;
}

.BtnGestionOcultar {
    background-color: #31A44C;
    margin: 0 auto;
    width: 50%;
    padding: .6rem 2rem;
    border-radius: 10px;
    border: none;
    outline: none;
    transition: .4s ease-in-out;
    transition: .2s all ease;
    color: white;
}


.GestionCuentaTitulo {
    text-align: center;
    padding: 0 2rem;
    padding-bottom: 2rem;
    font-weight: 700;
    color: black;
}

.BotonesCambiarDatos {
    display: flex;
    justify-content: center;
    align-self: center;
    flex-direction: row;
    gap: 20px;
}

#BotonGuardarCambioCuenta {
    margin: 0 auto;
    width: 100%;
    padding: .6rem 2rem;
    border-radius: 10px;
    border: none;
    outline: none;
    transition: .4s ease-in-out;
    background-color: #387CDF;
    transition: .2s all ease;
    color: white;
    /* margin: 1rem; */
}

#BotonGuardarCambioCuenta:hover {
    background-color: #336fc9;
}

/* boton cancelar edicion */

#btnCancelarEdicion {
    margin: 0 auto;
    margin-top: 1rem;
    width: 50%;
    padding: .7rem 2rem;
    border-radius: 10px;
    border: none;
    outline: none;
    transition: .4s ease-in-out;
    background-color: #434343;
    transition: .2s all ease;
    color: white;
}

#btnCancelarEdicion:hover {
    background-color: #626262;
}

/* eliminar cuenta */

#BotonEliminarCambioCuenta {
    margin: 0 auto;
    width: 50%;
    padding: .7rem 2rem;
    border-radius: 10px;
    border: none;
    outline: none;
    transition: .4s ease-in-out;
    background-color: #ed2121;
    transition: .2s all ease;
    color: white;
    /* margin: 1rem; */
}

#BotonEliminarCambioCuenta:hover {
    background-color: #e23a3a;
}

#btnEditarPerfil {
    width: 50%;
    padding: .7rem 2rem;
    border-radius: 10px;
    border: none;
    outline: none;
    transition: .4s ease-in-out;
    background-color: #387CDF;
    color: white;
    margin-bottom: 1rem;
}

#btnGestionCuenta {
    margin: 0 auto;
    width: 50%;
    padding: .7rem 2rem;
    border-radius: 10px;
    border: none;
    outline: none;
    transition: .4s ease-in-out;
    background-color: #31a44c;
    color: white;
}



@media (min-width:700px) and (max-width: 1300px) {
    .PerfilContainer {
        padding: 8rem 0rem;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-family: "Montserrat", sans-serif;
    }

    /* card de perfil - Donde se ve la informacion - container padre */
    .ProfileInformation {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.169);
        height: auto;
    }
}

@media (max-width: 900px) {
    .PerfilContainer {
        padding-top: 6rem;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 100vh;
    }

    /* titulo - "configuracion de tu perfil" */
    .TituloPerfil {
        text-align: center;
        padding: 0 1rem;
        font-size: 1.3rem;
        padding-bottom: 1rem;
    }

    /* card de perfil - Donde se ve la informacion - container padre */
    .ProfileInformation {
        display: flex;
        justify-content: center;
        border-radius: 0px;
        width: 100%;
        /* Con esto regulo el ancho de la card padre, son dos container este y el infogrid */
        box-shadow: none;
        padding: 0;
        /* Padding de costado de la card principal*/
    }


    .ImagePerfil {
        width: 100%;
        height: auto;
        border-radius: 0;

    }

    .infoPerfil {
        padding: 4rem;
    }

    .ContainerDataSave {
        width: 100%;
        display: flex;
        justify-content: left;
        flex-direction: column;
        align-items: safe;
        padding: 1.2rem;
        padding-bottom: 0;
        box-sizing: border-box;
    }

    .form-label {
        font-weight: 600;
        font-size: 1rem;

    }

    .TituloDataSave {
        font-weight: 600;


    }

    .mb-3 {
        margin-top: 1rem;
    }

    .containerEditarInformacion {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .ContainerBotonGestionCuenta {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 1rem 0;
    }

    .BtnGestionOcultar {
        margin: 0 auto;
        width: 70%;
        padding: .6rem 2rem;
        border-radius: 10px;
        transition: none;
        transition: none;
    }

    #BtnEditarInfo {
        background-color: #0c6dfd;
        margin: 0 auto;
        width: 70%;
        padding: .6rem 2rem;
        border-radius: 10px;
        border: none;
        outline: none;
        transition: .4s ease-in-out;
        transition: .2s all ease;
        color: white;
    }

    .DatoSave {
        font-weight: 600;
    }

    .TituloDropdown {
        font-weight: 600;
        font-size: 1.2rem;
    }

    .BotonesPerfil {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    #btnEditarPerfil {
        width: 100%;
        padding: .4rem 2rem;
        margin-top: 20px;
        /* Agregamos un margen superior para separarlo del resto de los elementos */
    }

    .DataPefil {
        margin: 10px 0;
        width: 100%;
        word-break: keep-all;
    }

    .descripcion {
        width: 100%;
        white-space: pre-wrap;
        word-break: keep-all;

    }


    /* estilo datos modificables */

    .containerTextoPerfil {
        padding-top: 0;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
    }

    .FormPerfil {
        padding-top: 2rem;
    }

    #BotonGuardarPerfil {
        width: 100%;
        padding: .7rem 2rem;
        border: none;
        outline: none;
        transition: .4s ease-in-out;
        color: white;
    }

    #BotonGuardarPerfil:hover {
        background-color: #0c6dfd;
    }

    /* gestion de cuenta */

    .AccountManagement {
        padding: 2rem 0rem;
        width: 100%;
    }

    .ManejoDeCuenteTitulo {
        margin-bottom: 2rem;
        font-weight: 700;
        text-align: center;
    }

    #FormManejoCuenta {
        padding: 1rem;
        display: flex;
        flex-direction: column;
    }

    .GestionCuentaTitulo {
        text-align: center;
        padding: 0 2rem;
        padding-bottom: 2rem;
        font-weight: 700;
        color: black;
    }

    .BotonesCambiarDatos {
        display: flex;
        justify-content: center;
        align-self: center;
        flex-direction: column;
        gap: 20px;
    }

    #BotonGuardarCambioCuenta {
        margin: 0 auto;
        width: 100%;
        padding: .6rem 2rem;
        border: none;
        outline: none;
        transition: .4s ease-in-out;
        background-color: #387CDF;
        transition: .2s all ease;
        color: white;
        /* margin: 1rem; */
    }

    #BotonGuardarCambioCuenta:hover {
        background-color: #336fc9;
    }

    /* boton cancelar edicion */

    #btnCancelarEdicion {
        margin: 0 auto;
        margin-top: 1rem;
        width: 100%;
        padding: .7rem 2rem;
        border: none;
        outline: none;
        transition: .4s ease-in-out;
        background-color: #434343;
        transition: .2s all ease;
        color: white;
    }

    #btnCancelarEdicion:hover {
        background-color: #626262;
    }

    /* eliminar cuenta */

    #BotonEliminarCambioCuenta {
        margin: 0 auto;
        width: 100%;
        padding: .7rem 2rem;
        border: none;
        outline: none;
        transition: .4s ease-in-out;
        background-color: #ed2121;
        transition: .2s all ease;
        color: white;
        /* margin: 1rem; */
    }

    #BotonEliminarCambioCuenta:hover {
        background-color: #e23a3a;
    }

    #btnEditarPerfil {
        width: 100%;
        padding: .6rem 1rem;
        border: none;
        outline: none;
        transition: .4s ease-in-out;
        color: white;
        margin-bottom: 1rem;
    }

    #btnGestionCuenta {
        width: 100%;
        padding: .6rem 1rem;
        border: none;
        outline: none;
        transition: .4s ease-in-out;
        color: white;
    }

}