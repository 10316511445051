@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

/* Container padre */

#Introduction {
    display: flex;
    /* background: linear-gradient(180deg, rgba(32, 117, 147, 1) 13%, rgba(16, 65, 83, 1) 53%, rgba(2, 19, 25, 1) 98%); */
    background-image: url(../Images/BackgroundIntrouction.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    /* Asegura que la imagen de fondo cubra todo el contenedor */
    background-repeat: no-repeat;
    /* Evita que la imagen se repita */
    background-position: center;
    /* Centra la imagen de fondo */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding-top: 5rem;
    width: 100%;
}

/* Titulo  */
#TituloIntroduction {
    font-size: 4rem;
    background: radial-gradient(45% 100% at 50% 50%, #ffffff 30%, #e9e9e9 100%);
    filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.546));

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    text-align: center;
}

/* Container de btn */

#BotonesConteiner {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 3rem;
    display: flex;
    flex-direction: row;
    /* Mantiene botones en fila*/
    gap: 1px;
    /* Espacio ente botones*/
    margin-bottom: 60px;
    /* con esto empujo la descripcion para abajo*/
    font-family: "Montserrat", sans-serif;
}


#EnlaceIntroduccion {
    padding: 0;
}

#btnDondeDonar {
    font-size: 1.3rem;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    border-radius: 300px;
    padding: .8rem 2rem;
    border: 1px rgba(153, 152, 152, 0.639) solid;
    filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.387));
    background-color: #116ffd;
    color: white;
    transition: .3s all ease-in-out;
    backdrop-filter: blur(1px);
    /* Aplica el filtro blur al fondo del botón */

}

#btnDondeDonar:hover {
    transform: translateY(-2px);
    background-color: #3b80e8;

}

#btnAprenderMas {
    font-size: 1.3rem;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    border-radius: 300px;
    padding: 0.8rem 2rem;
    background-color: rgba(234, 235, 237, 0.621);
    /* Color de fondo semitransparente */
    filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.546));
    border: 1px solid rgb(177, 175, 175);
    color: white;
    transition: 0.3s all ease-in-out;
    backdrop-filter: blur(5px);
    /* Aplica el filtro blur al fondo del botón */
}

/* Opcional: cambiar la apariencia del botón al pasar el cursor */
#btnAprenderMas:hover {
    background-color: rgba(234, 235, 237, 0.682);
    /* Cambia ligeramente el fondo al pasar el cursor */
    backdrop-filter: blur(12px);
    /* Aumenta el blur al pasar el cursor */
    transform: translateY(-2px);
}


#DescripcionIntroduccion {
    font-size: large;
    color: #ffffff;
    background: radial-gradient(60% 100% at 50% 50%, #ffffff 10%, #747474 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: initial;
    filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.834));
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

@media (max-width: 900px) {
    #Introduction {
        padding: 5rem 1.2rem 0rem 1.2rem;
        align-items: center;
        height: 100vh;
    }

    #TituloIntroduction {
        font-size: 1.7rem;
        font-weight: 600;
    }

    #DescripcionIntroduccion {
        font-size: 1rem;
        color: rgb(221, 215, 215);
        text-align: center;
        font-weight: 400;


    }

    #BotonesConteiner {

        padding: 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    #EnlaceIntroduccion {
        width: 100%;

    }

    #btnDondeDonar {
        font-size: 1rem;
        font-weight: 300;
        font-family: "Montserrat", sans-serif;
        border-radius: 300px;
        padding: .5rem 1.5rem;
        border: 1px rgba(255, 253, 253, 0.368) solid;
        filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.387));
        background-color: #116ffd;
        color: white;
        transition: none;
    }

    #btnDondeDonar:hover {
        transform: none;
        background-color: #116ffd;

    }

    #btnAprenderMas {
        font-size: 1rem;
        font-weight: 300;
        font-family: "Montserrat", sans-serif;
        border-radius: 300px;
        padding: .5rem 1.5rem;
        background-color: #eaebed52;
        filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.546));
        border: 1px rgb(177, 175, 175) solid;
        color: white;
        transition: none;
    }

    #btnAprenderMas:hover {
        transform: none;
        background-color: #eaebed52;
    }




}

/* media querie para laptop */


@media (min-width:1000px) and (max-width: 1300px) {
    #Introduction {
        padding: 5rem 1rem 0rem 1rem;
        height: 100vh;
    }

    #TituloIntroduction {
        font-size: 3rem;
        font-weight: 600;
    }

    #DescripcionIntroduccion {
        font-size: 1rem;
        color: rgb(221, 215, 215);
        text-align: center;
        font-weight: 400;


    }

    #BotonesConteiner {

        padding: 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    #EnlaceIntroduccion {
        width: 100%;

    }

    #btnDondeDonar {
        font-size: 1rem;
        font-weight: 300;
        padding: .5rem 1.5rem;
    }

    #btnAprenderMas {
        font-size: 1rem;
        font-weight: 300;
        padding: .5rem 1.5rem;
    }
}

/* fin media querie laptop */