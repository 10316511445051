@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


/* Container padre eventos */

.EventosContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: #eeeeee;
    padding-top: 8rem;
    padding-left: 10rem;
    /* padding izquierdo */
    padding-right: 10rem;
    /* padding derecho */
    padding-bottom: 4rem;
    height: auto;
    /* Padding de arriba*/
}

/* Titulo de la pagina principal de eventos, es el titulo "EVENTOS" */
#TituloEventosContainer {

    text-align: center;
    font-size: 3rem;
    color: rgb(34, 34, 34);
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
}


/* Contenedor que guarda todos los eventos */

.containerCardsEventos {
    padding: 1rem;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 30px;
    min-height: 100vh;
}

/* Container de los botones de crear eventos y mis eventos */
.ContainerBoton {
    display: flex;
    gap: 30px;
}

/* botones de crear evento y mis eventos */

.BtnEventosPrincipal {
    border: none;
    padding: 0;
    background-color: #1871E3;
    color: white;
    padding: .6rem 1.5rem;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.13);
    transition: .3s all ease-in-out;
}

.BtnCrearEventoPopUp {
    border: none;
    padding: 0;
    background-color: #1871E3;
    color: white;
    padding: .6rem 1.5rem;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.13);
    transition: .3s all ease-in-out;
}

.BtnEventosPrincipal:hover {
    transform: translateY(-1px);
    background-color: #2c7be3;
}

/* estilos de las cards individuales */

.CardEventos {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    width: 32%;
    min-height: auto;
    max-height: 730px;
    max-width: auto;
    padding: 0;
    border-radius: 24px;
    background-color: #f1f1f1;
    /* border: 1px #f1f1f1bd solid; */
    /*Esto se podria poner o sacar VER ESTO*/
    overflow: hidden;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.247);
}


/* .ImagenContainer {
    width: 20%;
    max-height: 350px;
    height: 350px;
    min-height: 350px;
    border: 20px;
} */

.ImagenContainer img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

#ImagenCard {
    width: 100%;
    min-height: 300px;
    /* altura minima de la foto, esto para que las cards no se rompa si no carga foto*/
    height: 300px;
    /* altura fija para que la foto se cargue predeterminadamente a esa medida*/
    max-height: 300px;
    /* altura maxima, para que no se excede de ese tamano y se rompa la card*/
    object-fit: cover;
    object-position: center;
}

/* Estilos de texto Titulo de la card, fecha y etiquetas */
.textoContainer {
    padding: 2rem;
    width: 100%;
    color: rgb(34, 34, 34);
}

/* Titulo de la card */

#TituloEventos {
    font-size: 1.4rem;
    font-family: "Montserrat", sans-serif;
    color: black;
    font-weight: 700;
    word-wrap: break-word;
}

/* Horario de la card */
#HorarioEventos {
    font-size: .95rem;
    font-family: "Montserrat", sans-serif;
    color: rgb(93, 93, 93);
}

/* descripcion de las cards */

#DecripcionEventos {
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    color: black;
    word-break: keep-all;
}

#DescripcionDeCardEventos {
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    color: black;
    padding: 0 2rem;
    height: 70px;
    overflow-y: auto;
    word-wrap: normal;
    word-break: break-all;
    /*con esto existe un scroll en la descripcion de cada evento pero la card no se va a romper*/
}

/* Ocultar scrollbar en navegadores basados en WebKit */
#DescripcionDeCardEventos::-webkit-scrollbar {
    display: none;
}

/* Boton de saber mas, esta dentro la card individual */

.containerBotonEvento {
    display: flex;
    padding-top: 2rem;
    padding-bottom: 3rem;
    justify-content: center;
}


/* Container Etiquetas - aca se guardan todas las etiquetas */

#etiquetasContainer {
    display: flex;
    height: 50px;
    flex-direction: row;
    justify-content: left;
    gap: 10px;
    margin-bottom: 1rem;
}

/* Estilo para la etiqueta individual */

#EtiquetaCard {
    padding: .3rem .4rem;
    background-color: rgba(32, 3, 3, 0.322);
    color: white;
    font-weight: 600;
    width: 100%;
    border-radius: 10px;
}

/* Sub-Container de las etiquetas, aca se seleccionan cada una */

.EtiquetaContainer {
    position: relative;
    display: inline-block;
    width: 50px;
}

/* Estilo ToolTip de cada etiqueta */
.TituloEtiqueta {
    position: absolute;
    width: 200px;
    /* NO TOCAR */
    bottom: -90px;
    /* NO TOCAR */
    text-align: center;
    left: 80%;
    /* NO TOCAR */
    transform: translateX(-60%);
    /* NO TOCAR */
    background-color: #ebefff;
    color: black;
    font-weight: 600;
    padding: 10px 10px;
    /* NO TOCAR */
    border-radius: 5px;
    opacity: 0;
    /* NO TOCAR */
    visibility: hidden;
    /* NO TOCAR */
    font-size: .8rem;
    /* NO TOCAR */
    font-family: "Montserrat", sans-serif;
    transition: all 0.3s ease;
}

/* Estilo para mostrar el título de la etiqueta al pasar el MOUSE sobre ella */
.EtiquetaContainer:hover .TituloEtiqueta {
    opacity: 1;
    /* NO TOCAR */
    visibility: visible;
    /* NO TOCAR */
    bottom: -65px;
    /* NO TOCAR */
}



/* paginacion eventos */

#PaginationButtonsEventos {
    display: flex;
    gap: 20px;
    flex-direction: row;
    padding: 0rem;
}

/* fin paginacion */

@media (max-width: 450px) {
    .EventosContainer {
        gap: 20px;
        padding-top: 8rem;
        padding-left: 0rem;
        padding-right: 0rem;
        padding-bottom: 0rem;
        /* Padding de arriba*/
    }

    .BtnEventosPrincipal {
        transition: none;
    }

    .BtnEventosPrincipal:hover {
        transform: none;
        background-color: none;
    }
}


@media (max-width: 500px) {

    .EventosContainer {
        gap: 20px;
        padding-top: 8rem;
        padding-left: 0rem;
        padding-right: 0rem;
        padding-bottom: 0rem;
        /* min-height:100vh !important; */
        /* Padding de arriba*/
    }

    .ImagenContainer {
        width: 100%;

    }

    #ImagenCard {
        width: 100%;
        min-height: 200px;
        height: 200px;
        max-height: 200px;
        object-fit: cover;
        object-position: center;
        border-radius: 15px 15px 0px 0px;
    }


    .EventosContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 6rem 0;
    }

    .CardEventos {
        display: flex;
        width: 90%;
        height: auto;
        padding: 0rem 0rem;
        transition: none;
        border-radius: 15px;
    }

    .CardEventos:hover {
        transform: none;
    }




    #DecripcionEventos {
        font-size: 1rem;
    }

    #DescripcionDeCardEventos {
        font-size: 1rem;
        font-family: "Montserrat", sans-serif;
        color: black;
        /* word-wrap: break-word; */
        /* Alternativa: usar word-break: break-word; */
        /* word-break: break-all; */
        /* Alternativa: usar break-word para cortar palabras largas sin guiones */
        padding: 0 1.5rem;
        height: 70px;
        overflow-y: auto;
        /*con esto existe un scroll en la descripcion de cada evento pero la card no se va a romper*/
    }

    #ContainerEventosBotones {
        display: flex;
        justify-content: left;
        flex-direction: column;
        gap: 10px;
    }

    .BotonesEventos {
        font-size: 1rem;
        font-weight: 300;
        border-radius: 300px;
        padding: .5rem 2rem;
    }

    #etiquetasContainer {
        display: flex;
        height: 30px;
        flex-direction: row;
        justify-content: left;
        gap: 10px;
    }

    .BtnEventosPrincipal {
        transition: none;
    }

    .BtnEventosPrincipal:hover {
        transform: none;
        background-color: none;
    }


    /* media querie para ipad */
}

@media (min-width: 700px) and (max-width: 999px) {
    .EventosContainer {
        gap: 20px;
        padding-top: 8rem;
        padding-left: 0rem;
        padding-right: 0rem;
        padding-bottom: 2rem;
        /* Padding de arriba*/
    }

    .EventosContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 6rem 0rem;
    }

    .CardEventos {
        width: 90%;
        min-height: auto;
        max-width: auto;
        padding: 0;
    }

    .containerBotonEvento {
        padding: 0;
        padding-bottom: 2rem;
    }

    #TituloEventosContainer {
        font-size: 2rem;
        font-weight: 700;
    }

    #ImagenCard {
        width: 100%;
        height: 170px;
    }

    /* tamano etiqueta */
    #etiquetasContainer {
        display: flex;
        height: 30px;
        flex-direction: row;
        justify-content: left;
        gap: 10px;
    }

    #DescripcionDeCardEventos {
        padding: 0 1.5rem;
        height: 70px;
        overflow-y: auto;
        /*con esto existe un scroll en la descripcion de cada evento pero la card no se va a romper*/
    }

    .BtnEventosPrincipal {
        transition: none;
    }

    .BtnEventosPrincipal:hover {
        transform: none;
        background-color: none;
    }
}


@media (min-width: 1514px) and (max-width: 1700px) {
    /* Container padre eventos */

    .EventosContainer {
        padding-top: 7rem;
        padding-left: 0;
        /* padding izquierdo */
        padding-right: 0;
        /* padding derecho */
        padding-bottom: 4rem;
    }


    /* Contenedor que guarda todos los eventos */

    .containerCardsEventos {
        padding-top: 1rem;
    }

    /* estilos de las cards individuales */

    .CardEventos {
        width: 30%;
    }


}

@media (min-width: 1000px) and (max-width: 1514px) {
    /* Container padre eventos */

    .EventosContainer {
        padding-top: 7rem;
        padding-left: 0;
        /* padding izquierdo */
        padding-right: 0;
        /* padding derecho */
        padding-bottom: 4rem;
    }


    /* Contenedor que guarda todos los eventos */

    .containerCardsEventos {
        padding-top: 1rem;
    }

    /* estilos de las cards individuales */

    .CardEventos {
        width: 450px;
    }


}