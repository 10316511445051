@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


#ContainerAyudar {
    background-color: #ffffff;
    /* background: rgb(2,19,25);
    background: linear-gradient(180deg, rgba(2,19,25,1) 28%, rgba(16,65,83,1) 84%); */
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 3rem 0;
}

#TituloBloqueAyudar {
    text-align: center;
}

#TituloAyudar {
    font-size: 2rem;
    font-family: "Montserrat", sans-serif;
    /* background: radial-gradient(15% 60% at 50% 50%, #fff 30%, hsla(0, 0%, 100%, .35) 100%);       */
    /* -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    color: rgb(19, 19, 19);
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-align: center;
}

#DescripcionAyudar {
    color: rgb(37, 37, 37);
    font-size: 1.2rem;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
}

#BloqueCards {
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 10rem;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

#card {
    z-index: 1;
    /* padding: 10px; */
    height: 15rem;
    line-height: 26px;
    padding: 30px 30px 36px;
    text-align: left;
    width: 23%;
    border-radius: 20px;
    background-color: #f1f1f1;
    background-image: url(/src/Components/Pages/Home/Images/Noiseimage.png);
    border: 1px #ffffff62 solid;
    transition: .3s ease-in-out;
    /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.531); */

}

.card:hover {
    transform: translateY(-3px);
}

.card-title {
    font-size: 1.7rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

.card-text {
    font-size: 1rem;
    color: rgb(24, 24, 24);
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
}


#EnlaceIntroduccion {
    text-align: center;
    font-size: 1.2rem;
    font-family: "Montserrat", sans-serif;
    padding: 1rem 2rem;
}

#EnlaceIntroduccion li {
    text-decoration: none;
    list-style: none;
}


#EnlaceVerMas {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.2rem;
    font-family: "Montserrat", sans-serif;
    padding: 1rem 2rem;
}

#EnlaceVerMas li {
    text-decoration: none;
    list-style: none;
}

/* Boton de las cards */
#botonDonar {
    font-size: 1.1rem;
    font-family: "Montserrat", sans-serif;
    border-radius: 300px;
    border: 1px rgba(2, 2, 2, 0.271) solid;
    background-color: #0C6DFD;
    color: white;
    transition: .3s all ease-in-out;
}

#botonDonar:hover {
    background-color: #3a85f4;
    color: white;
}

#BotonVerMas {
    padding: .5rem 1.9rem;
    font-size: 1.2rem;
    font-family: "Montserrat", sans-serif;
    border-radius: 300px;
    border: 1px rgba(218, 215, 215, 0.312) solid;
    background-color: #116EFD;
    color: rgb(255, 255, 255);
    transition: .4s all ease-in-out;
}

#BotonVerMas:hover {
    transform: translateY(-3px);
    background-color: #1964d3;
}

@media (max-width: 900px) {


    #ContainerAyudar {
        width: 100%;
        padding: 3rem 1rem;
    }

    #TituloAyudar {
        font-size: 1.5rem;

    }

    #DescripcionAyudar {
        font-size: 1rem;
    }

    #BloqueCards {
        padding: 1rem 0;
        gap: 10px;
    }

    #card {
        line-height: 26px;
        padding: 24px 24px 30px;
        width: 100%;
        height: auto;

        /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.531); */
    }

    .card:hover {
        transform: none;
    }

    #BotonVerMasSection {
        text-align: center;
        font-size: 1.2rem;
        font-family: "Montserrat", sans-serif;
    }

    #EnlaceIntroduccion {
        text-align: center;
        font-size: 1.2rem;
        font-family: "Montserrat", sans-serif;
        padding: 1rem 2rem;
    }

    #EnlaceIntroduccion li {
        text-decoration: none;
        list-style: none;
    }

    /* Boton de las cards */
    .botonDonar {
        font-size: 1.2rem;
        font-family: "Montserrat", sans-serif;
        border-radius: 300px;

    }

    #botonDonar {
        transition: none;
        background-color: #0C6DFD;
        ;
    }

    .botonVerMas {
        font-size: 1.5rem;
        font-family: "Montserrat", sans-serif;
        border-radius: 300px;
        padding: .3rem 1.5rem;
        transition: none;
    }

    .botonVerMas:hover {
        transform: none;
        background-color: #0C6DFD;

    }


}

@media (min-width: 768px) {
    .btn-secondary {
        display: none;
    }
}


@media (min-width:650px) and (max-width: 1199px) {

    #ContainerAyudar {
        width: 100%;
        height: 100%;
        padding: 3rem 0;
    }

    #TituloAyudar {
        font-size: 2rem;
    }

    #BloqueCards {
        padding: 1rem 1rem;
        gap: 10px;
    }

    #card {
        z-index: 1;
        /* padding: 10px; */
        height: 15rem;
        line-height: 26px;
        padding: 32px 32px 36px;
        width: 45%;

        /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.531); */

    }
}

/* media querie laptop */


@media (min-width:1200px) and (max-width: 1273px) {
    #ContainerAyudar {
        width: 100%;
        height: 100%;
        padding: 1rem 0;

    }

    #BloqueCards {
        padding: 1rem 1rem;
        gap: 7px;
    }

    #card {
        z-index: 1;
        /* padding: 10px; */
        height: 220px;
        line-height: 26px;
        padding: 1rem 1rem;
        width: 30%;

        /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.531); */

    }
}

@media (min-width:1274px) and (max-width: 1669px) {
    #ContainerAyudar {
        width: 100%;
        height: 100%;
        padding: 3rem 0;
    }

    #BloqueCards {
        padding: 1rem 3rem;
        gap: 7px;
    }

    #card {
        z-index: 1;
        /* padding: 10px; */
        height: 220px;
        line-height: 26px;
        padding: 1rem 1rem;
        width: 24%;

        /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.531); */

    }
}

/* Fin media querie laptop */