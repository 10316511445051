@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

nav {
    display: flex;
    justify-content: right;
    align-items: right;
    background-color: rgba(0, 0, 0, 0.445);
    backdrop-filter: blur(50px);
    padding: 15px 60px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
    position: fixed;
    width: 100%;
    z-index: 1000;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    height: 100%;
}

.LogoDonAR {
    width: 100%;
}

#Menu {
    flex: 1;
    display: flex;
    justify-content: right;
}

#navbar {
    /* Estilo de navbar */
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
}

#navbar li {
    padding: 0 30px;
}

#navbar li a {
    /* Estilo de letras del menu */
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-decoration: none;
    font-size: 1.1rem;
    color: white;
    transition: 0.3s ease-in-out;
    position: relative;
}

#navbar li:not(:last-child) a::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 4px;
    background-color: #ffffff;
    bottom: -7px;
    /* Ajusta este valor para cambiar el espacio */
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
}

#navbar li:not(:last-child) a:hover::before {
    transform-origin: left;
    transform: scaleX(1);
}





.btnLogin {
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif;
    background-color: #3b80e8ec;
    border: 1px rgba(255, 255, 255, 0.238) solid;
    padding: 8px 20px;
    border-radius: 30px;
    color: white;
    text-decoration: none;
    transition: .3s 0.3s ease-in-out;
}

.btnLogin:hover {
    background-color: #3a85f4;
    transform: translateY(-1px);
}

#BotonContainer {
    padding: 0 0rem;
    background-color: transparent;
    border: none;
}

.ContainerBotonHead {
    display: flex;
    gap: 20px;
}

.LogOutIcon {
    width: 34px;
}

#mobile {
    display: none;
}

@media screen and (max-width: 900px) {

    nav {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.445);
        height: 60px;
        padding-left: 2rem;
        padding-right: 3rem;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
        position: fixed;
        width: 100%;
        z-index: 1000;
    }

    #navbar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: fixed;
        top: 60px;
        right: -300px;
        width: 300px;
        height: 100vh;
        background-color: #2a3239;
        box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
        padding: 40px 0 0 15px;
        transition: 0.3s ease-in-out;
        z-index: 999;
    }

    .logo {
        width: 100px;
        height: 100%;
    }

    .LogoDonAR {
        width: 100%;
    }

    #navbar.active {
        right: 0px;
    }

    #navbar li {
        margin-bottom: 25px;
        font-size: 15px;
    }

    #mobile {
        display: block;
        align-items: center;
        /* position: fixed; */
        top: 20px;
        /* right: 20px; */
        z-index: 1001;
        /* border: 2px yellow solid; */
        height: 100%;
        width: 100%;
        cursor: pointer;
    }

    #bar {
        display: block;
        font-size: 30px;
        text-align: right;
        color: white;
        cursor: pointer;
    }

    .btnLogin {
        display: block;
        margin-top: 20px;
        /* Agregar margen superior para separar del resto de los elementos */
    }


    /* animaciones de hover */

    #navbar li a {
        /* Estilo de letras del menu */

        transition: none;
        position: relative;
    }

    #navbar li:not(:last-child) a::before {
        position: relative;
        transform-origin: none;
        transform: none;
        transition: none;
    }

    #navbar li:not(:last-child) a:hover::before {
        transform-origin: none;
        transform: none;
    }

    .ContainerBotonHead {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        /* border: 2px yellow solid; */
        gap: 20px;
    }

    .ProfileLoginContainer {
        margin: 0 auto;
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 40px;
    }

    .LogOutIcon {
        width: 35px;
    }

    .PerfilBtn {
        width: 35px;
    }



}



/* media querie para ipad */

@media screen and (min-width: 740px) and (max-width: 900px) {

    nav {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
        padding-left: 2rem;
        padding-right: 3rem;
        position: fixed;
        width: 100%;
        z-index: 1000;
        height: 70px;
    }

    #navbar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: fixed;
        top: 70px;
        right: -300px;
        width: 300px;
        height: 100vh;
        background-color: #2a3239;
        box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
        padding: 40px 0 0 15px;
        transition: 0.3s ease-in-out;
        z-index: 999;
    }


}