/* Container general */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


#ContainerCategorias {
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: #eeeeee;
    padding: 5rem 0rem;
    gap: 30px;

}

/* Titulo de categorias */

#TituloCategoria {
    font-size: 2rem;
    font-family: "Montserrat", sans-serif;
    color: rgb(48, 48, 48);
    font-weight: 700;
}

/* Bloque Cards */
.CardsBloque {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.CardsCategorias {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    padding: 2rem;
    gap: 10px;
    font-family: "Montserrat", sans-serif;
    text-align: center;
    width: 20%;
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.21);
    background-color: #00000046;
    background-image: url(/src/Components/Pages/Home/Images/Noiseimage.png);
    border: 1px #ffffff62 solid;
    transition: .3s ease-in-out;
}

.CardsCategorias:hover {
    transform: translateY(-3px);
}

#IconCategoria {
    width: 20%;
}

#tituloInteriorCards {
    font-size: 2rem;
    color: white;
}

#EnlaceCategorias {
    padding-top: 1.3rem;
    padding-bottom: 1rem;
}

#EnlaceCategorias li a {
    text-decoration: none;
}

#EnlaceCategorias li {

    list-style: none;
}


#BtnCategoria {
    padding: .7rem 3rem;
    font-size: 1.2rem;
    width: 60%;
    font-family: "Montserrat", sans-serif;
    border-radius: 300px;
    border: 1px rgba(21, 119, 216, 0.53) solid;
    background-color: #0C6DFD;
    color: white;
    transition: .3s all ease-in-out;
}

#BtnCategoria:hover {
    background-color: #347eec;
}


@media (max-width: 900px) {

    #ContainerCategorias {
        padding-top: 120px;
        height: fit-content;
        gap: 30px;
    }

    /* Titulo de categorias */

    #TituloCategoria {
        text-align: center;
        font-size: 2rem;
    }

    /* Bloque Cards */
    .CardsBloque {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        gap: 20px;
    }

    .CardsCategorias {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 1;
        padding: 20px 0;
        font-family: "Montserrat", sans-serif;
        text-align: center;
        width: 40%;
        border-radius: 20px;
        transition: none;
    }

    .CardsCategorias:hover {
        transform: none;
    }

    #tituloInteriorCards {
        font-size: 1.5rem;
        color: white;
    }




    #BtnCategoria {
        /* margin-top: 1rem; */
        padding: .6rem 1rem;
        font-size: 1.2rem;
        width: 70%;
        font-family: "Montserrat", sans-serif;
        border-radius: 300px;
        border: 1px rgba(2, 2, 2, 0.271) solid;
        background-color: #0C6DFD;
        color: white;
        transition: none;
    }

    #BtnCategoria:hover {
        background-color: #0C6DFD;
    }
}