@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.ContainerForm {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eeeeee;
  padding: 30rem 4rem;
  font-family: "Montserrat", sans-serif;
  gap: 20px;
  height: 90vh;
}


.formSoporte {
  display: flex;
  width: 50%;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  /* padding: 2rem; */
  position: relative;
  transition: .6s ease-in-out all;

}




.title {
  font-size: 3rem;
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  padding-left: 30px;
  color: #1871E3;
}

.title::before {
  width: 18px;
  height: 18px;
}

.title::after {
  width: 18px;
  height: 18px;
  animation: pulse 1s linear infinite;
}

/* efecto breath */

.title::before,
.title::after {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  border-radius: 50%;
  left: 0px;
  background-color: #1871E3;
}

.message,
.signin {
  font-size: 1.2rem;
  color: rgba(21, 21, 21, 0.817);
}

.signin {
  text-align: center;
}

.signin a:hover {
  text-decoration: underline #1871E3;
}

.signin a {
  color: #00bfff;
}


.formSoporte label {
  position: relative;
}

.formSoporte label .input {
  background-color: #f9f9f9;
  width: 100%;
  padding: 20px 05px 05px 10px;
  outline: 0;
  border: 1px solid rgba(237, 237, 237, 0.695);
  border-radius: 9px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.226);

}

.formSoporte label .input+span {
  color: #727070;
  position: absolute;
  left: 10px;
  top: 0px;
  font-size: 0.9em;
  cursor: text;
  transition: 0.3s ease;
}

.formSoporte label .input:placeholder-shown+span {
  top: 12.5px;
  font-size: 1rem;
}

.formSoporte label .input:focus+span,
.formSoporte label .input:valid+span {
  color: #1871E3;
  top: 0px;
  font-size: .9rem;
  font-weight: 600;
  padding-top: .2rem;
}

.input {
  font-size: medium;
}

.TituloInfoNuestra {
  font-weight: 700;
}

.ContactoForm {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.ContactoForm:hover {
  text-decoration: none;
  color: rgb(101, 101, 101);
  cursor: pointer;
}

.btnForm {
  width: 150px;
  font-family: inherit;
  margin: 0 auto;
  font-size: 1rem;
  background: #1871E3;
  color: white;
  padding: 0.9rem .7rem;
  /* padding-left: 0.9em; */
  display: flex;
  align-items: center;
  border: none;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
  margin-top: 2rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.171);

}

.btnForm:hover {
  background-color: #1871E3;
}

.btnForm span {
  display: block;
  margin-left: 1rem;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
}

.btnForm svg {
  display: block;
  margin-left: .5rem;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}

.btnForm:hover .svg-wrapper {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

.btnForm:hover svg {
  transform: translateX(2.5rem) rotate(45deg) scale(1.1);
}

.btnForm:hover span {
  transform: translateX(8rem);
}

.btnForm:active {
  transform: scale(0.95);
}


/* Estilo para el mensaje de error en el formulario de soporte */

/* Estilos para los mensajes de error */
/* Estilos para el contenedor de mensajes de error */
.error-containerSoporte {
  text-align: left;
  margin-bottom: 10px;
  /* Espacio entre mensaje de error y formulario */
}

/* Estilos para los mensajes de error */
.error-messageSoporte {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;

  color: #721c24;
  font-size: 14px;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  padding: .8rem 2rem;
  margin-top: 0;
  /* Espacio superior al mensaje de error */
  background-color: #f8d7da;
  /* Fondo rojo solo cuando hay errores */
}


@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}


@keyframes pulse {
  from {
    transform: scale(0.9);
    opacity: 1;
  }

  to {
    transform: scale(1.8);
    opacity: 0;
  }
}





@media (max-width: 900px) {

  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

  .ContainerForm {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 4rem;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 1rem;
    font-family: "Montserrat", sans-serif;
    gap: 20px;
    min-height: 100vh;
    height: auto;
  }


  .formSoporte {
    display: flex;
    width: 80%;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    padding-top: 2rem;
    padding-bottom: 2rem;
    position: relative;
    transition: .6s ease-in-out all;
    height: auto;

  }

  .FormularioBloque2 {
    display: flex;
    justify-content: start;
    height: auto;
    width: 90%;
    flex-direction: column;
    gap: 10px;
    padding: 0 2rem;
    position: relative;
    transition: .6s ease-in-out all;

  }



  .title {
    font-size: 2rem;
    font-family: "Montserrat", sans-serif;
    /* padding-left: 0px; */
  }

  .message,
  .signin {
    font-size: 1.1rem;
  }

  .signin {
    text-align: center;
  }


  .formSoporte label {
    position: relative;
  }

  .formSoporte label .input {
    width: 100%;
    padding: 20px 05px 05px 10px;
    outline: 0;
    border-radius: 7px;

  }

  .formSoporte label .input+span {
    position: absolute;
    left: 10px;
    top: 0px;
    font-size: 0.9em;
    cursor: text;
    transition: 0.3s ease;
  }

  .formSoporte label .input:placeholder-shown+span {
    top: 12.5px;
    font-size: 1rem;
  }

  .formSoporte label .input:focus+span,
  .formSoporte label .input:valid+span {
    color: #6d87d6;
    top: 0px;
    font-size: .9rem;
    font-weight: 600;
    padding-top: .2rem;
  }

  .input {
    font-size: medium;
  }

  .TituloInfoNuestra {
    font-weight: 700;
  }

  .ContactoForm {
    text-decoration: none;
    cursor: pointer;
  }

  .ContactoForm:hover {
    text-decoration: none;
    cursor: pointer;
  }

  .btnForm {
    width: 150px;
    font-family: inherit;
    margin: 0 auto;
    font-size: 1rem;
    padding: 0.9rem .7rem;
    /* padding-left: 0.9em; */
    display: flex;
    align-items: center;
    border: none;
    border-radius: 10px;
    overflow: hidden;
    transition: none;
    cursor: pointer;
    margin-top: 0%;


  }

  .btnForm:hover {
    background-color: #1B7DFC;
  }

  .btnForm span {
    display: block;
    margin-left: 1rem;
    transition: none;
    font-weight: 500;
  }

  .btnForm svg {
    display: block;
    margin-left: .5rem;
    transform-origin: center center;
    transition: none;
  }

  .btnForm:hover .svg-wrapper {
    animation: none;
  }

  .btnForm:hover svg {
    transform: none;
  }

  .btnForm:hover span {
    transform: none;
  }

  .btnForm:active {
    transform: none;
  }

  @keyframes fly-1 {
    from {
      transform: translateY(0.1em);
    }

    to {
      transform: translateY(-0.1em);
    }
  }


  @keyframes pulse {
    from {
      transform: scale(0.9);
      opacity: 1;
    }

    to {
      transform: scale(1.8);
      opacity: 0;
    }
  }





}