@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

/* Estilo de container grande */
.LoginContainer {
    display: flex;
    justify-content: center;
    height: auto;
    padding-top: 4rem;
}

/* Titulo header */

#heading1 {
    text-align: center;
    color: black;
    font-size: 2rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

/* Container de form */

#FormularioLoginContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    /* border: 2px yellow solid; */

}

/* Bloques 1 y 2 */

/* bloque de foto */

.bloque1 {
    width: 50%;
    /* border: 2px yellow solid; */
}

#FondoLogin {
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 100vh;
    /* border-radius: 30px 0 0 30px; */
}


/* Bloque de inputs */
.bloque2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    /* border-radius: 0 30px 30px 0px; */
    background-color: #f1f1f1;
    background-image: url(/src/Components/Pages/Home/Images/Noiseimage.png);


}

/* Form dentro de bloque 2 */

.form1 {
    display: flex;
    width: 85%;
    flex-direction: column;
    gap: 10px;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 0.4em;
    /* border: 2px yellow solid; */
}

.form1:hover {
    background-color: none;
    box-shadow: none;
}


.formRecuperar.hover {
    background-color: white;
    box-shadow: none
}


/* Icons de inputs */
.field ion-icon {
    font-size: 24px;
    /* color: rgb(99, 98, 98); */
    /*color original*/
    color: rgb(166, 166, 166);
    margin-right: .5rem;
    margin-left: .2rem;
}

/* inputs  */
.field {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    border-radius: 10px;
    /*   30px original*/
    padding: 0.7em;
    /* border: 1px rgba(19, 19, 19, 0.071) solid; */
    border: 1px rgba(255, 255, 255, 0.574) solid;
    outline: none;
    /* color: black; */

    color: black;

    background-color: rgb(224, 224, 224);
    font-family: "Montserrat", sans-serif;
}

.input-field {
    background: none;
    border: none;
    outline: none;
    width: 100%;
    color: black;
}

/* Container de botones de login */

.botonesLogincontainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    /* margin-top: 2.5em; */
    font-family: "Montserrat", sans-serif;
}

/* Boton acceder */
.buttonLoginAccess {
    width: 300px;
    padding: .7rem 2rem;
    border-radius: 30px;
    border: none;
    outline: none;
    transition: .2s ease-in-out;
    background-color: #387CDF;
    color: white;
}

.buttonLoginAccess:hover {
    background-color: #3d85f0;
}

/* Boton registrarse */

.buttonRegister {
    width: 300px;
    padding: .7rem 2rem;
    border-radius: 30px;
    border: none;
    outline: none;
    transition: .4s ease-in-out;
    background-color: #31a44c;
    color: white;
}

.buttonRegister:hover {
    background-color: #4fad65;
    color: white;
}

.buttonForgotPassword {
    font-family: "Montserrat", sans-serif;
    margin-top: 1.5rem;
    border-radius: 30px;
    border: none;
    background-color: transparent;
    color: black;
    text-decoration: dashed;
}

.buttonForgotPassword:hover {
    transform: none;
}

.buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}


@media (max-width: 900px) {


    @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

    /* Estilo de container grande */
    .LoginContainer {
        justify-content: center;
        padding-top: 4.4rem;
        width: 100%;
        padding: 2rem 0rem 0rem 0rem;

    }

    /* Container de form */

    #FormularioLoginContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        /* border: 2px yellow solid; */

    }


    /* Boton acceder */
    .buttonLoginAccess {
        width: 170px;
        padding: .5rem 1rem;
    }

    .buttonLoginAccess:hover {
        background-color: #3d85f0;
    }

    /* Boton registrarse */

    .buttonRegister {
        width: 170px;
        padding: .5rem 1rem;
    }

    /* Bloques 1 y 2 */

    .bloque1 {
        display: none;
    }



    .bloque2 {
        width: 100%;
        height: 100%;
        border-radius: 0px;
        display: flex;
        direction: column;
    }


    .form1 {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 20px;
        padding: 5rem 2rem;
        /* border: 2px yellow solid; */
    }

    .form1:hover {
        box-shadow: none;
    }

    .formRecuperar.hover {
        box-shadow: none
    }

    .field {

        gap: 0.8em;
        border-radius: 10px;
        padding: 0.7em;
        border: 1px rgba(19, 19, 19, 0.071) solid;
    }

    .botonesLogincontainer {
        display: flex;
        width: 80%;
        flex-direction: column;
        gap: .5rem;
    }

    .button1 {
        width: 100%;
        margin-bottom: .5rem;
        outline: none;
        transition: none;
    }

    .button1:hover {
        transform: none;
    }

    .button2 {
        width: 100%;
        transition: none;

    }

    .button2:hover {
        transform: none;
    }

    .button3 {
        width: 100%;
        transition: none;
    }

    .button3:hover {
        transform: none;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
    }



}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 1000px) {

    /* Estilo de container grande */
    .LoginContainer {
        justify-content: center;
        padding-top: 0;
        width: 100%;
        height: 90vh;
        padding: 0;

    }


    /* Boton acceder */
    .buttonLoginAccess {
        width: 300px;
        padding: .8rem 2rem;
    }

    .buttonLoginAccess:hover {
        background-color: #3d85f0;
    }

    /* Boton registrarse */

    .buttonRegister {
        width: 300px;
        padding: .8rem 2rem;
    }

    /* Bloques 1 y 2 */

    .bloque1 {
        display: none;
    }



    .bloque2 {
        width: 100%;
        height: 100%;
        border-radius: 0px;
        display: flex;
        direction: column;
    }


    .form1 {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 20px;
        padding: 5rem 2rem;
        /* border: 2px yellow solid; */
    }

    .form1:hover {
        box-shadow: none;
    }

    .formRecuperar.hover {
        box-shadow: none
    }

    .field {

        gap: 0.8em;
        border-radius: 10px;
        padding: 0.7em;
        border: 1px rgba(19, 19, 19, 0.071) solid;
    }

    .botonesLogincontainer {
        display: flex;
        width: 80%;
        flex-direction: column;
        gap: .5rem;
    }

    .button1 {
        width: 100%;
        margin-bottom: .5rem;
        outline: none;
        transition: none;
    }

    .button1:hover {
        transform: none;
    }

    .button2 {
        width: 100%;
        transition: none;

    }

    .button2:hover {
        transform: none;
    }

    .button3 {
        width: 100%;
        transition: none;
    }

    .button3:hover {
        transform: none;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}