#heading3 {
    text-align: center;
    color: black;
    font-size: 2rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

#aclaracion2 {
    text-align: center;
    margin-bottom: 20px;
    font-family: "Montserrat", sans-serif;
    color: black;
}

.form {
    width: 90%;
    /* border: 2px yellow solid; */

}


.form:hover {
    width: 90%;
    border: none;
}

.btnOlvideContrasena {
    margin: 0;
    padding: 1rem;
    /* border: 2px yellow solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    background-color: none;
}

/* container boton olvide contrasena */
.ContainerBotonOlvideContrasena {
    margin: 0;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

#btnSendCode {
    padding: .7rem 3rem;
    border-radius: 30px;
    border: none;
    outline: none;
    transition: .2s ease-in-out;
    font-family: "Montserrat", sans-serif;
    background-color: #0C6DFD;
    color: white;
}

#btnSendCode:hover {
    background-color: #4a88e6;
}

/* bloque de foto */

.bloque1 {
    width: 50%;
    /* border-radius: 30px 0px 0px 30px; */
    /* border: 2px yellow solid; */
}

/* Bloque de inputs de olvide contraseña */

.bloque4 {
    display: flex;
    flex-direction: column;
    padding: 4rem;
    justify-content: center;
    align-items: center;
    width: 50%;
    border-radius: 0 30px 30px 0px;
    /* background-color: white; */
    background-color: #f1f1f1;
}

.bloque4:hover {
    /* background-color: white; */
    box-shadow: none;
    transform: none;
}


/* Estilo para el mensaje de error en el formulario de recuperar contraseña */

.error-message {
    color: #721c24;
    font-size: 14px;
    margin-top: 5px;
    text-align: center;
    padding: 5px;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
}

@media (max-width: 900px) {

    .formRecuperar {
        padding: 1rem;
    }

    #aclaracion2 {
        text-align: center;
        margin-bottom: 2rem;
    }

    .form {
        width: 100%;
        /* border: 2px yellow solid; */

    }

    .form:hover {
        width: 90%;
        border: none;
    }

    .btnOlvideContrasena {
        margin: 0;
        padding: 1rem;
        /* border: 2px yellow solid; */
        display: flex;
        justify-content: center;
        align-items: center;
    }



    .bloque4 {
        width: 100%;
        height: 600px;
        padding: 0 2rem;
        border-radius: 0;
        display: flex;
        direction: column;
        justify-content: center;
        align-items: center;
    }

    .bloque4:hover {
        box-shadow: none;
        transform: none;
    }
}