/* Estilos generales */
.MisEventosContainer {
    padding: 8rem 0rem;
    width: 100%;
    background-color: #e7e7e7;
    /* border: 2px yellow solid; */
}

#TituloMisEventosContainer {
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 700;
}

/* Contenedor de tarjetas */
.containerCardsEventos {
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: center;
    padding: 0;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;

}

/* Tarjetas de eventos */
.eventCard {
    border-radius: 8px;
    width: 28%;
    text-align: center;
}

.eventCard .CardMiEventos {
    width: 100%;
    background-color: #f1f1f1;
    border-radius: 20px 20px 0px 0px;
    box-shadow: none;
}



/* Estilos para la parte de abajo de las cards donde estan los botones */
.eventActions {
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
    gap: 20px;
    background-color: #f1f1f1;
    border-radius: 0px 0px 20px 20px;
    width: 100%;
}

/* input color */

.InputFormEventos {
    background-color: rgb(255, 255, 255);
    border: 1px rgba(192, 192, 192, 0.555) solid;
    border-radius: 7px;
}

/* boton para editar - color verde */
.editButton {
    font-size: 1rem;
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
    border-radius: 10px;
    padding: .5rem 2rem;
    border: 1px rgba(132, 132, 132, 0.139) solid;
    background-color: #0a854b;
    color: white;
    transition: .3s all ease-in-out;
}

.editButton:hover {
    background-color: #0fa55f;
}

/* boton de eliminar */
.deleteButton {
    font-size: 1rem;
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
    border-radius: 10px;
    padding: .5rem 2rem;
    border: 1px rgba(132, 132, 132, 0.139) solid;
    background-color: #bb1321;
    color: white;
    transition: .3s all ease-in-out;
}

.deleteButton:hover {
    background-color: #d81b2b;
}

/* Estilo del contenedor del popup */

.editPopup {
    position: fixed;
    top: 9%;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 900px;
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    z-index: 1050;
    overflow: auto;
    border-radius: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.267);
}


.deletePopup {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 900px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050;
    overflow: auto;
    border-radius: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.267);
}


/* Estilo del contenido del popup */
.deletePopupContent,
.editPopupContent {
    font-family: "Montserrat", sans-serif;
    background: #e9e9e9;
    padding: 3rem;
    border-radius: 20px;
    overflow-y: auto;
    /* Permitir desplazamiento en el contenido */
    max-height: 100%;
    /* Ajuste máximo de altura para permitir desplazamiento */
    width: 100%;
    box-sizing: border-box;
}

/* Ocultar la barra de desplazamiento en los popups */
.deletePopupContent::-webkit-scrollbar,
.editPopupContent::-webkit-scrollbar {
    display: none;
}

.TituloEditarEvento {
    font-weight: 700;
}

/* Botones de confirmación y cancelación */
.confirmDeleteButton,
.cancelDeleteButton,
.saveEditButton,
.cancelEditButton {
    margin-right: 10px;
}

.BotonGuardarEventos {
    font-size: 1rem;
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
    border-radius: 10px;
    padding: .5rem 2rem;
    border: 1px rgba(132, 132, 132, 0.139) solid;
    background-color: #1871E3;
    color: white;
    transition: .3s all ease-in-out;
}

.BotonCancelarEvento {
    background-color: #6c757d;
    font-size: 1rem;
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
    border-radius: 10px;
    padding: .5rem 2rem;
    border: 1px rgba(132, 132, 132, 0.139) solid;
    color: white;
    transition: .3s all ease-in-out;
}

.confirmDeleteButton {
    font-size: 1rem;
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
    border-radius: 10px;
    padding: .5rem 2rem;
    background-color: red;
    border: 1px rgba(132, 132, 132, 0.139) solid;
    color: white;
    transition: .3s all ease-in-out;
}

.cancelDeleteButton {
    font-size: 1rem;
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
    border-radius: 10px;
    padding: .5rem 2rem;
    background-color: #6c757d;
    border: 1px rgba(132, 132, 132, 0.139) solid;
    color: white;
    transition: .3s all ease-in-out;
}

.MisEventosDescripcion {
    max-height: 150px;
    overflow-y: scroll;
}

/* Ocultar scrollbar en navegadores basados en WebKit */
.MisEventosDescripcion::-webkit-scrollbar {
    display: none;
}

.MisEventosContainerTexto {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
}

/* -------------------- Estilos de mi evento, cuando se abre el evento post creacion -------------------------*/

#ContainerMievento {
    display: flex;
    padding-top: 8rem;
    padding-bottom: 3rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #e9e9e9;
    font-family: "Montserrat", sans-serif;
}

.CardNuevoEvento {
    background-color: #f1f1f1;
    border-radius: 20px;
    width: 45%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.247);

}

#ContainerImagenNuevoEvento {
    width: 100%;
    min-height: 400px;
    max-height: 400px;
    overflow: hidden;
}

#imagenNuevoEvento {
    width: 100%;
    object-fit: fill;
    object-position: center;
    border-radius: 20px 20px 0 0;
}

/* container grande texto */
.ContainertextoNuevoevento {
    padding: 1.5rem 3rem;

}

#TituloDeMiEventoPrincipal {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
}

/* container que guarda todo el texto de mi evento */
.ContainerInfoMievento {
    /* border: 2px yellow solid; */
    display: flex;
    flex-direction: column;
    gap: 10px;
    word-break: normal;
    /* Las palabras no se cortan arbitrariamente */
    overflow-wrap: break-word;


}

/* tamano de titulo de mi evento */

.TituloMieventoInfo {
    font-size: 1.5rem;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
}

/* tamano de letras de la info del evento */
.TextoMiEventoInfo {
    font-size: 1.1rem;
}



/* etiquetas */


/* Container padre  */

#etiquetasContainerMisEventosID {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 1rem;
    padding-left: 0rem;
    padding-right: 0rem;
}

/* segundo container */
.EtiquetaContainerMisEventosImg {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;

}

/* Etiqueta card */
#EtiquetaCardMisEventos {
    padding: .3rem .4rem;
    background-color: rgba(116, 116, 116, 0.432);
    background-image: url(/src/Components/Pages/Home/Images/Noiseimage.png);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.356);
    color: white;
    font-weight: 600;
    width: 100%;
    border-radius: 10px;
}

.TituloEtiquetaMisEventos {
    position: absolute;
    width: auto;
    /* bottom: 30px; */
    text-align: center;
    left: 110%;
    transform: translateX(-80px);
    background-color: #ebefff;
    color: black;
    font-weight: 600;
    padding: 10px 10px;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    font-size: .8rem;
    font-family: "Montserrat", sans-serif;
    transition: all 0.3s ease;
}


.EtiquetaContainerMisEventosImg:hover .TituloEtiquetaMisEventos {
    opacity: 1;
    visibility: visible;
    bottom: auto;
    margin-top: 1rem;
}

/* fin etiquetas */



@media (max-width: 917px) {

    /* Estilos generales */
    .MisEventosContainer {
        padding: 5rem 0;
    }

    #TituloMisEventosContainer {
        font-size: 1.5rem;
        margin-bottom: 0;
    }

    /* Contenedor de tarjetas */
    .containerCardsEventos {
        display: flex;
        padding-top: 1rem;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 100%;
        justify-content: center;
    }

    /* Tarjetas de eventos */
    .eventCard {
        border-radius: 8px;
        width: 90%;
        text-align: center;
    }

    .eventCard .CardMiEventos {
        width: 100%;
        border-radius: 20px 20px 0 0;
        transition: none;
    }

    .eventCard .CardMiEventos:hover {
        transform: none;
    }

    /* Estilos para la acción de eventos */
    .eventActions {
        padding-bottom: 2rem;
        display: flex;
        justify-content: center;
        gap: 20px;
        width: 100%;
    }

    /* Estilo del contenedor del popup */
    .deletePopup,
    .editPopup {
        top: 10%;
        height: 90%;
        width: 100%;
        /* Ajusta esto según sea necesario */
        transform: translateX(-50%);
    }

    /* Estilo del contenido del popup */
    .deletePopupContent,
    .editPopupContent {
        display: flex;
        position: fixed;
        flex-direction: column;
        padding: 3rem 2rem;
        /* Reduce el padding en móviles */
        max-height: 100%;
        /* Ajuste máximo de altura para permitir desplazamiento */
    }

    .containerBtnModeventos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 10px;
    }

    .BtnGuardarMisEventos {
        border-radius: 10px;
        padding: .5rem 2rem;
        margin-bottom: 1rem;
        transition: none;
        width: 100%;
    }


    /* Botones de confirmación y cancelación */
    .confirmDeleteButton,
    .saveEditButton {
        margin-bottom: .5rem;
    }

    #ContainerMievento {
        display: flex;
        padding-top: 3rem;
        padding-bottom: 0rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .CardNuevoEvento {
        border-radius: 0;
        width: 100%;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.247);

    }

    #ContainerImagenNuevoEvento {
        width: 100%;
        min-height: auto;
        max-height: 200px;
        overflow: hidden;
    }

    #imagenNuevoEvento {
        width: 100%;
        object-fit: fill;
        object-position: center;
        border-radius: 0;
    }

    /* container grande texto */
    .ContainertextoNuevoevento {
        padding: 1.5rem;
    }

    /* container que guarda todo el texto de mi evento */
    .ContainerInfoMievento {
        gap: 5px;
    }


    /* tamano de titulo de mi evento */

    #TituloDeMiEventoPrincipal {
        font-size: 1.3rem;
        font-weight: 600;
        text-align: center;
    }

    .TituloMieventoInfo {
        font-size: 1.2rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    /* tamano de letras de la info del evento */
    .TextoMiEventoInfo {
        font-size: 1rem;
    }

    #etiquetasContainerMisEventosID {
        margin-bottom: 1rem;
        padding-left: .5rem;
        padding-right: .5rem;
    }


    /* etiquetas */
}

/* --------------------------------------------------------------------------------------- */

/* Media querie para ipad */

@media (min-width:800px) and (max-width: 1000px) {

    /* Estilos de mi evento, cuando se abre el evento post creacion */

    #ContainerMievento {
        display: flex;
        padding-top: 1rem;
        padding-bottom: 3rem;
        width: 100%;
        min-height: 90vh;
    }

    .CardNuevoEvento {
        border-radius: 20px;
        width: 70%;

    }

    #ContainerImagenNuevoEvento {
        width: 100%;
        min-height: 250px;
        max-height: 250px;
        overflow: hidden;
    }

    #imagenNuevoEvento {
        width: 100%;
        object-fit: fill;
        object-position: center;
        border-radius: 20px 20px 0 0;
    }

    /* container grande texto */
    .ContainertextoNuevoevento {
        padding: 1.5rem 2.5rem;
    }

    /* container que guarda todo el texto de mi evento */
    .ContainerInfoMievento {
        /* border: 2px yellow solid; */
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    /* tamano de titulo de mi evento */

    .TituloMieventoInfo {
        font-size: 1.5rem;
        font-weight: 700;
    }

    /* tamano de letras de la info del evento */
    .TextoMiEventoInfo {
        font-size: 1.1rem;
    }

    .eventCard {
        width: 40%;
    }

}


/* etiquetas */


/* Etiquetas container */

#etiquetasContainerMisEventosID {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 1rem;
}

/* Estilo para el contenedor de la etiqueta */
.EtiquetaContainerMisEventosImg {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;

}

/* Estilo para la etiqueta */
#EtiquetaCardMisEventos {
    padding: .3rem .4rem;
    font-weight: 600;
    width: 100%;
    border-radius: 10px;
}



.TituloEtiquetaMisEventos {
    position: absolute;
    width: auto;
    /* bottom: 30px; */
    text-align: center;
    left: 110%;
    transform: translateX(-80px);
    font-weight: 600;
    padding: 10px 10px;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    font-size: .8rem;
    font-family: "Montserrat", sans-serif;
    transition: all 0.3s ease;
}


#EtiquetaCardMisEventos:hover .TituloEtiquetaMisEventos {
    opacity: 1;
    visibility: visible;
    bottom: auto;
    margin-top: 1rem;
}



/* Fin Media querie ipad */


@media (min-width: 1000px) and (max-width: 1400px) {
    #ContainerMievento {
        padding-top: 8rem;
        padding-bottom: 3rem;
        width: 100%;
    }

    .CardNuevoEvento {
        border-radius: 20px;
        width: 50%;

    }

    /* Tarjetas de eventos */
    .eventCard {
        border-radius: 8px;
        width: 40%;
        text-align: center;
    }

    #ContainerImagenNuevoEvento {
        width: 100%;
        min-height: 320px;
        max-height: 320px;
        overflow: hidden;
    }

    #imagenNuevoEvento {
        width: 100%;
    }

    /* container grande texto */
    .ContainertextoNuevoevento {
        padding: 2rem 3rem;
    }

    /* container que guarda todo el texto de mi evento */
    .ContainerInfoMievento {
        gap: 8px;

    }

    /* tamano de letras de la info del evento */
    .TextoMiEventoInfo {
        font-size: 1.1rem;
    }


}