@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


#AccionContainer {
    display: flex;
    background-color: #f3f3f3;
    /* background: rgb(16, 65, 83);
    background: linear-gradient(180deg, rgba(16, 65, 83, 1) 67%, rgba(16, 65, 83, 1)95%); */
    flex-direction: row;
    gap: 30px;
    padding: 3rem;
    width: 100%;
    height: 80vh;
    justify-content: center;
    align-items: center;
}

.content {
    display: flex;
    color: white;
    flex-direction: column;
    padding: 30px;
    width: 50%;
    justify-content: center;


}

#TituloAccion {
    /* background: radial-gradient(20% 100% at 25% 50%, #ffffffe0 30%, hsla(0, 0%, 100%, .35) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    color: rgb(17, 17, 17);
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 2.5rem;

}

#DescripcionAccion {
    font-size: 1.2rem;
    color: black;
    font-weight: 300;
    font-family: "Montserrat", sans-serif;

}

#ImageDonar {
    flex: 1;
    width: 40%;
    height: 90%;
    object-fit: cover;
    object-position: center;
    /* border-radius: 0px 40px 0 40px; */
    border-radius: 10px;
}

@media (max-width: 900px) {



    #AccionContainer {
        flex-direction: column;
        gap: 1px;
        padding: 1rem;
    }

    .content {
        display: flex;
        color: white;
        flex-direction: column;
        padding: 1rem;
        width: 100%;
        justify-content: center;


    }

    #TituloAccion {
        font-weight: 600;

    }

    #DescripcionAccion {
        font-size: 1rem;
    }

    #ImageDonar {
        flex: 1;
        width: 100%;
        border-radius: 11px;
    }

}

@media (min-width: 750px) and (max-width: 900px) {

    #AccionContainer {
        display: flex;
        background-color: #E7E7E7;
        /* background: rgb(16, 65, 83);
        background: linear-gradient(180deg, rgba(16, 65, 83, 1) 67%, rgba(16, 65, 83, 1)95%); */
        flex-direction: column;
        gap: 30px;
        padding: 3rem;
        width: 100%;
        height: 60vh;
    }

    #ImageDonar {

        width: 90%;
        height: auto;
        object-fit: cover;
        object-position: center;
        /* border-radius: 0px 40px 0 40px; */
        border-radius: 10px;
    }
}