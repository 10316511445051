@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

/* Estilo de container grande */
.LoginContainer {
    display: flex;
    justify-content: center;
    height: 100vh;
    background-color: #f1f1f1;
}

.iconoInputForm {
    font-size: 64px;
    color: rgb(0, 0, 0);
}

/* Container de form */

#FormularioLoginContainer {
    display: flex;
    flex-direction: row;
    width: 80%;
    height: 100vh;
    /* border: 2px yellow solid; */
}

/* Bloques 1 y 2 */

.bloque1 {
    width: 50%;
    height: 100vh;
    /* border: 2px yellow solid; */
}

.bloque3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    background-color: #f1f1f1;
}



#FondoLogin {
    width: 80%;
}

#aclaracion {
    margin: 2em;
    text-align: center;
    color: black;
    /* el original es negro*/
    font-family: "Montserrat", sans-serif;
}

#heading {
    text-align: center;
    color: black;
    font-size: 2rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

.buttonSendRequestRegister {
    padding: .8rem 3rem;
    border-radius: 30px;
    border: none;
    outline: none;
    transition: .2s ease-in-out;
    font-family: "Montserrat", sans-serif;
    background-color: #0C6DFD;
    color: white;
    /* margin-bottom: 20px; */
}

.buttonSendRequestRegister:hover {
    background-color: #3d85f0;
}

.form2 {
    display: flex;
    flex-direction: column;
    gap: 0.7em;
    padding-left: 3em;
    padding-right: 3em;
    padding-bottom: 0.4em;
    border-radius: 30px;
}

.form2:hover {
    box-shadow: none;
    transform: none;
}




.field {
    background-color: white;
}

.input-icon {
    height: 1.3em;
    width: 1.3em;
    fill: black;
}

.input-field {
    background: none;
    border: none;
    outline: none;
    width: 100%;
    /* color: #d3d3d3; */
    color: black;
}

.form2 .btnRegistrarse {
    display: flex;
    justify-content: center;
    flex-direction: row;
    transition: .3s ease-in-out all;
    margin: 10px;
}

/* Estilo para el contenedor de mensajes de error */
.error-container {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
}

.error-container ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.error-container li {
    margin: 5px 0;
}

.error-message {
    font-weight: 600;
}

/* Restante de tus estilos aquí */

@media (max-width: 900px) {



    #FormularioLoginContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100vh;
        /* border: 2px yellow solid; */
    }


    /* Bloques 1 y 2 */

    .bloque1 {
        display: none;
    }

    .bloque3 {
        width: 100%;
        padding: 3rem 0;
        /* podding top y bottom*/
        height: 100%;
        border-radius: 0;
        /* margin-bottom: 4rem; */



    }


    img {
        width: 100%;
    }

    #aclaracion {
        margin-bottom: 1rem;
        margin-left: 0;
        margin-right: 0;
        text-align: center;
    }

    #heading {
        margin-bottom: 1rem;
    }

    .button4 {
        padding: 0.5em;
        padding-left: 2.3em;
        padding-right: 2.3em;
        border-radius: 30px;
        transition: none;

    }

    .form2 {
        display: flex;
        flex-direction: column;
        gap: 0.7em;
        border-radius: 0;
    }

    .form2:hover {
        box-shadow: none;
        transform: none;
    }

    .form2 .btnRegistrarse {
        flex-direction: column;
        transition: none;
        margin: 10px;
    }

    .btnRegistrarse:hover {
        transform: none;
    }


}