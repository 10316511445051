/* Seccion De Perfiles */

/* Contenedor padre */

#ContainerDineroPadre {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10rem 5rem;
    align-items: center;
    background-color: #f3f6f9;
    font-family: "Montserrat", sans-serif;
    max-width: 100%;
}

/* Card perfil */

.DineroHijo {
    border-radius: 24px;
    width: 70%;
    /* overflow: hidden; */
    /* SACAR ESTO*/
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0rem 0rem 2rem 0;
    background-color: #f1f1f1;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.562);

}


/* Logo */

.ImagenContainer img {
    width: 100%;
    max-height: 100%;
    border-radius: 24px 24px 0 0px;
}

/* #LogoDinero {
    width: 10px;
    border-radius: 24px 24px 0 0px;

} */

/* .LogoCardPerfilCategoria{
    border: 2px yellow solid;
} */

.HorarioPerfil{
    padding-top: 1.5rem;
}


/* Etiquetas container */

#etiquetasContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 1rem;
}

/* Estilo para la etiqueta */
#EtiquetaCard {
    padding: .3rem .4rem;
    background-color: rgba(116, 116, 116, 0.432);
    background-image: url(/src/Components/Pages/Home/Images/Noiseimage.png);
    color: white;
    font-weight: 600;
    width: 100%;
    border-radius: 10px;
}

/* Estilo para el contenedor de la etiqueta */
.EtiquetaContainer {
    position: relative;
    /* Posición relativa para el posicionamiento absoluto del título */
    display: inline-block;
    /* Para que el contenedor se ajuste al contenido */
    width: 50px;
    height: 50px;
}

/* Estilo para el título de la etiqueta */
.TituloEtiqueta {
    position: absolute;
    width: auto;
    /* bottom: 30px; */
    text-align: center;
    left: 110%;
    transform: translateX(-80px);
    background-color: #ebefff;
    color: black;
    font-weight: 600;
    padding: 10px 10px;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    font-size: .8rem;
    font-family: "Montserrat", sans-serif;
    transition: all 0.3s ease;
}

/* Estilo para mostrar el título de la etiqueta al pasar el ratón sobre ella */
.EtiquetaContainer:hover .TituloEtiqueta {
    opacity: 1;
    visibility: visible;
    bottom: auto;
    margin-top: 1rem;
}


/* Container con titulos */
#ContainerTituloBtn {
    display: flex;
    flex-direction: row;
    padding-bottom: 2rem;
}

#Box1Texto {
    width: 70%;
    /* border: 2px yellow solid; */
}

#Box1Texto a {
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.Box2Btn {
    width: 50%;
    /* border: 2px yellow solid; */
    gap: 5px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

#icon {
    color: rgb(255, 255, 255);
    background-color: rgba(42, 42, 42, 0.147);
    border: 1px rgba(255, 255, 255, 0.841) solid;
    background-image: url(/src/Components/Pages/Home/Images/Noiseimage.png);
    border-radius: 15px;
    padding: .6rem;
    font-size: 64px;
    transition: .2s all ease-in-out;
}

#icon:hover {
    transform: translateY(-3px);
}

.TextoDinero {
    padding: 3rem 3rem;
    width: 100%;

}



#TituloDinero {
    font-size: 3rem;
    font-weight: 700;
}

.DescripcionPerfil {
    font-size: 1rem;
    word-break:keep-all;
    /* Alternativa: usar word-break: break-word; */
    /* word-break: break-all; */
    /* Alternativa: usar break-word para cortar palabras largas sin guiones */

}

#DescripcionDinero {
    font-size: 1.5rem;
    line-height: 2.5rem;

    padding-top: 0;
    padding-bottom: 1rem;
    /* word-wrap: break-word; */
    /* word-break:keep-all; */
    word-break:keep-all;

    /* Alternativa: usar word-break: break-word; */
    /* word-break: break-all; */
    /* Alternativa: usar break-word para cortar palabras largas sin guiones */
}

#BulletDinero {
    font-size: 1.5rem;
}

.Mapa {
    width: 95%;
    justify-content: center;
}

#MapaGoogle {
    width: 100%;
    height: 30rem;
    border-radius: 16px;
}

.ContainerMP {
    width: 100%;
    padding-top: 1rem;
}

.BtnMercadopago {
    border: none;
    background-color: #4181E2;
    padding: .7rem 1rem;
    border-radius: 10px;
    color: white;
}

/* Media Query */
@media (max-width: 900px) {
    #ContainerDineroPadre {
        padding-top: 0rem;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;

    }

    #LogoDinero {
        border-radius: 0px;
        height: 250px;
        object-fit: cover;
        object-position: center;
    }

    .DineroHijo {
        width: 100%;
        border-radius: 0px;
    }

    #ContainerTituloBtn {
        flex-direction: column;
        padding-bottom: 1rem;
    }

    #Box1Texto {
        width: 100%;
    }

    /* estilo de texto direccion */
    #Box1Texto a {
        text-decoration: none;
        font-size: 1rem;
        color: rgb(102, 102, 102);
        cursor: pointer;
    }

    .Box2Btn {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-top: 1rem;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: left;
        align-items: center;
    }

    #icon {

        padding: .6rem;
        font-size: 64px;
        transition: none;
    }

    #icon:hover {
        transform: none;
    }




    .TextoDinero {
        padding: 0 1.5rem;
        /* Ajustado */
    }

    #TituloDinero {
        font-size: 2rem;
        padding-top: 1.3rem;
        /* Ajustado */
    }

    #DescripcionDinero {
        font-size: 1rem;
        /* Ajustado */
        line-height: 2rem;
        /* Ajustado */
        padding-top: 1.5rem;
        /* Ajustado */
        padding-bottom: 1.5rem;
        /* Ajustado */
    }

    #BulletDinero {
        font-size: 1rem;
        /* Ajustado */
    }

    .Mapa {
        width: 95%;
    }

    #MapaGoogle {
        width: 100%;
        height: 30rem;
        border-radius: 5px;
    }


    /* iconos de la categoria que son */

    .TituloEtiqueta {
        position: absolute;
        width: 100px;
        bottom: -90px;
        text-align: center;
        left: 80%;
        transform: translateX(-60%);
        background-color: #ebefff;
        color: black;
        font-weight: 600;
        padding: 10px 10px;
        border-radius: 5px;
        opacity: 0;
        visibility: hidden;
        font-size: .7em;
        font-family: "Montserrat", sans-serif;
        transition: all 0.3s ease;
    }

    /* Estilo para mostrar el título de la etiqueta al pasar el ratón sobre ella */
    .EtiquetaContainer:hover .TituloEtiqueta {
        opacity: 1;
        visibility: visible;
        bottom: -78px;
    }


}