#FooterSection {
    display: flex;
    background-color: #1b2430;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 2rem 0;
}

.Menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* border: 2px yellow solid; */
    width: 100%;
}

.Menu ul {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    list-style: none;
}

.Menu ul li a {
    text-decoration: none;
    color: white;
    font-family: "Montserrat", sans-serif;
}


.Menu ul li a {
    /* Estilo de letras del menu */

    transition: none;
    position: relative;
}

.Menu ul li a::before {
    position: relative;
    transform-origin: none;
    transform: none;
    transition: none;
}

.Menu li a:hover::before {
    transform-origin: none;
    transform: none;
}

#Copyright {
    font-size: 1rem;
    text-align: center;
    width: 100%;
    font-family: "Montserrat", sans-serif;
    color: rgb(179, 177, 177);
}

.LogoFooter{
    width: 6%;
    user-select: none;
}

@media (max-width: 900px) {
    #FooterSection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 1rem 0;
        text-align: center;
    }

    .Menu {
        display: flex;
        width: 100%;
        justify-content: center;
        flex-direction: row;
        /* border: 2px yellow solid; */

    }

    .Menu ul {
        list-style: none;
    }

    .Menu ul li a {
        text-decoration: none;
        color: white;
        font-size: 1rem;
        transition: none;
    }

    .Menu ul li a:hover {
        text-decoration: none;
        color: white;
        font-size: 1rem;
    }

    .Menu ul li a::before {
        content: '';
        position: none;
        width: none;
        height: none;
        border-radius: 4px;
        background-color: none;
        bottom: none;
        /* Ajusta este valor para cambiar el espacio */
        left: none;
        transform-origin: none;
        transform: none;
        transition: none;
    }

    .Menu ul li a:hover::before {
        transform-origin: none;
        transform: none;
    }

    #Copyright {
        width: 100%;
        justify-content: center;
        font-size: .7rem;
        color: rgb(179, 177, 177);
    }

    .LogoFooter{
        width: 20%;
        margin-bottom: .5rem;
        user-select: none;
    }
}