#EstadisticasContainer {
    background-color: #f3f3f3;
    padding: 5rem 10rem;
    max-width: 100%;
}

.Bloqueestadisticas {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 2rem 10rem;
}

/* bloque individual de estadisticas */

.Estadistica {

    text-align: center;
}

#TituloEstadisticas {
    text-align: center;
    font-size: 2rem;
    padding-bottom: 2rem;
    color: black;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
}

/* descripcion estadisticas */

.descripcionEstadisticas {
    text-align: center;
    font-size: 1.2rem;
    padding: 0 5rem;
    padding-bottom: 2rem;
    color: black;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}


#NumeroEst {
    font-size: 3.2rem;
    font-weight: bolder;
    color: #027DFC;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

.SignoMas {
    color: #027DFC;
}

#TextEst {
    /* color: white; */
    color: black;
    font-size: 1.2rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}





@media (max-width: 900px) {
    #EstadisticasContainer {
        padding: 2rem 2rem;
        max-width: 100%;
    }

    .Bloqueestadisticas {
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        padding: 0;
    }

    /* bloque individual de estadisticas */

    .Estadistica {

        text-align: center;
    }

    #TituloEstadisticas {
        text-align: center;
        font-size: 1.5rem;
        padding-bottom: 1rem;
        color: black;
        font-family: "Montserrat", sans-serif;
    }

    /* descripcion estadisticas */

    .descripcionEstadisticas {
        text-align: center;
        font-size: 1rem;
        padding: 0rem;
        padding-bottom: 2rem;
        color: black;
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
    }


    #NumeroEst {
        font-size: 1.4rem;
        color: #027DFC;
        font-family: "Montserrat", sans-serif;
        font-weight: 800;
    }

    .SignoMas {
        color: #027DFC;
        font-weight: 800;
    }

    #TextEst {
        /* color: white; */
        color: black;
        font-size: 1rem;
        font-family: "Montserrat", sans-serif;
        font-weight: 300;
    }



}