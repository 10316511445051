/* Perfiles.jsx */

/* Container padre */
.AsistenciaContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 9rem 0rem;
    background: #eeeeee;
}

.BtnOrdenarGeolocalizacion {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.226);
    padding: .6rem 3rem;
    border: 1px rgba(132, 132, 132, 0.139) solid;
    background-color: #377ADB;
    color: white;
    transition: .3s all ease-in-out;
}

/* CardsTemplate.jsx */

/* Estilos de cards */

.containerCards {
    display: flex;
    min-height: 100vh;
    width: 90%;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

/* Cards container */

.CardAsistencia {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 28%;
    height: 590px;
    padding: 0;
    border-radius: 20px;
    background-color: #f1f1f1;
    overflow: hidden;
    transition: .3s ease-in all;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.247);

}

.CardAsistencia:hover {
    /* Opcional se puede sacar*/
    transform: translateY(-3px);
}

/* Etiquetas container */

#etiquetasContainer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    gap: 10px;
    margin-bottom: 1rem;
}

/* Estilo para la etiqueta */
#EtiquetaCard {
    padding: .3rem .4rem;
    background-color: rgb(233, 233, 233);
    color: white;
    font-weight: 600;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.356);
}

/* Estilo para el contenedor de la etiqueta */
.EtiquetaContainer {
    position: relative;
    display: inline-block;
    width: 50px;

}

/* Estilo para el título de la etiqueta */
.TituloEtiqueta {
    position: absolute;
    width: 200px;
    bottom: -90px;
    text-align: center;
    left: 80%;
    transform: translateX(-90%);
    background-color: #ebefff;
    color: black;
    font-weight: 600;
    padding: 10px 10px;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    font-size: .8rem;
    font-family: "Montserrat", sans-serif;
    transition: all 0.3s ease;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.541);
}

/* Estilo para mostrar el título de la etiqueta al pasar el ratón sobre ella */
.EtiquetaContainer:hover .TituloEtiqueta {
    opacity: 1;
    visibility: visible;
    bottom: -65px;
}


/* imagen container */

.ImagenContainer {
    width: 100%;
    /* height: 100%; */
}

#ImagenCategoriaCard {
    width: 100%;
    object-fit: cover;
    object-position: center;
    /* border-radius: 30px 0 0px 30px; */
}

.textoAsistenciaContainer {
    padding: 2rem;
    width: 100%;
    height: 100%;
    /* color: white; */
}

#TituloAsistenciaContainer {
    text-align: center;
    font-size: 2.5rem;
    color: black;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    padding: 1rem 3rem;
}

#TituloAsistencia {
    font-size: 1.2rem;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    color: black;
}

#HorarioAsistencia {
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    /* color: white; */
    color: black;
}

/* #DecripcionAsistencia {
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    color: white;
} */

#ContainerBotones {
    display: flex;
    justify-content: center;
    padding: 1rem;
    /* border: 2px yellow solid; */
    gap: 1px;
}

#BotonLink {
    font-size: 1rem;
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.226);
    padding: .6rem 3rem;
    border: 1px rgba(132, 132, 132, 0.139) solid;
    background-color: #377ADB;
    color: white;
    transition: .3s all ease-in-out;
}

#BotonLink:hover {
    /* transform: translateY(-2px); */
    background-color: #3b80e8;

}

/* container exterior botones */
#PaginationButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;

}

/* Estilos de boton */
.BtnNextAndPrevious {
    display: flex;
    padding: .5rem 1.5rem;
    border-radius: 40px;
    border: none;
    background-color: #2f74db;
    transition: .2s all ease-in;
}

.BtnNextAndPrevious:disabled {
    background-color: gray;
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
    user-select: none;
}



.OnBtnContainerDespues {
    display: flex;
    gap: 20px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    color: white;
}

.OnBtnContainerAntes {
    display: flex;
    gap: 20px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    color: white;
}

ion-icon {
    font-size: 34px;
    color: white;
    transition: .2s all ease-in;
}

.OnBtnContainerDespues:hover ion-icon {
    transform: translateX(3px);
}

.OnBtnContainerAntes:hover ion-icon {
    transform: translateX(-3px);
}


/* media querie celular */


@media (max-width: 900px) {

    .AsistenciaContainer {
        display: flex;
        flex-direction: column;
        /*Esto hace que sea en columnas*/
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding-top: 6rem;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 3rem;
        /*Padding lateral y de arriba del container*/
    }

    /* CardsTemplate.jsx */

    .CardAsistencia {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: center;
        height: auto;
        width: 100%;
        transition: none;
    }

    .CardAsistencia:hover {
        transform: none;
    }

    .ImagenContainer {
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #ImagenCategoriaCard {
        height: 200px;
        border-radius: 20px 20px 0px 0px;
    }

    .textoAsistenciaContainer {
        padding: 2rem 2rem;
        width: 100%;
    }

    #TituloAsistenciaContainer {
        text-align: center;
        font-size: 2rem;
        color: black;
        font-family: "Montserrat", sans-serif;
        padding: 0rem 2rem;
    }

    .TituloDeCategoria {
        color: black;
    }

    #TituloAsistencia {
        font-size: 1.2rem;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
    }

    #HorarioAsistencia {
        font-size: .8rem;
        font-family: "Montserrat", sans-serif;
    }

    #DecripcionAsistencia {
        font-size: 1rem;
        font-family: "Montserrat", sans-serif;
        color: white;
    }

    #ContainerBotones {
        display: flex;
        align-items: center;
        width: 100%;
        padding-top: 2rem;
        gap: 0;
    }

    #BotonLink {
        font-size: 1rem;
        font-weight: 300;
        width: 100%;
        font-family: "Montserrat", sans-serif;
        /* border-radius: 30px; */
        padding: .6rem 3rem;
        border: 1px rgba(195, 195, 195, 0.139) solid;
        transition: none;
    }

    #BotonLink:hover {
        transform: translateY(0);

    }

    /* container exterior botones */
    #PaginationButtons {
        padding: 2rem 0rem 0rem 0rem;
        gap: 10px;

    }

    /* Estilos de boton */
    .BtnNextAndPrevious {
        display: flex;
        padding: .5rem 1rem;
        border: none;
        background-color: #2f74db;
        transition: .2s all ease-in;
    }

    .OnBtnContainerDespues {
        display: flex;
        gap: 10px;
        font-family: "Montserrat", sans-serif;
        color: white;
        font-size: .9rem;
        /* padding: 0 .5rem; */
    }

    .OnBtnContainerAntes {
        display: flex;
        gap: 10px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-family: "Montserrat", sans-serif;
        color: white;
        font-size: .9rem;
        /* padding: 0 .5rem; */
    }

    ion-icon {
        font-size: 1.8rem;
        transition: none;
    }

    .OnBtnContainerDespues:hover ion-icon {
        transform: none;
    }

    .OnBtnContainerAntes:hover ion-icon {
        transform: none;
    }

    .BtnNextAndPrevious:hover {
        background-color: #2f74db;
    }


    /* Icons hover */


    .TituloEtiqueta {
        position: absolute;
        width: 100px;
        bottom: -90px;
        text-align: center;
        left: 80%;
        transform: translateX(-60%);
        background-color: #ebefff;
        color: black;
        font-weight: 600;
        padding: 10px 10px;
        border-radius: 5px;
        opacity: 0;
        visibility: hidden;
        font-size: .7em;
        font-family: "Montserrat", sans-serif;
        transition: all 0.3s ease;
    }

    /* Estilo para mostrar el título de la etiqueta al pasar el ratón sobre ella */
    .EtiquetaContainer:hover .TituloEtiqueta {
        opacity: 1;
        visibility: visible;
        bottom: -78px;
    }

}

@media (min-width: 700px) and (max-width: 1000px) {

    /* Container padre */
    .AsistenciaContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding-top: 8rem;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 3rem;
    }

    .containerCards {
        display: flex;
        width: 100%;
        gap: 20px;
    }

    /* Cards container */

    .CardAsistencia {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 45%;
        height: auto;
        transition: none;

    }
}